import { h } from 'preact';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function NotFoundPage(_: NotFoundPageProps) {
  return (
    <section>
      <h3>Page not found</h3>
      <p>Are you looking for something here?</p>
    </section>
  );
}

// Required for the routing
interface NotFoundPageProps {
  default: true;
}
