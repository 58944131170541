import { h } from 'preact';
import { Link, route } from 'preact-router';
import { setUser, useUser } from '../lib/user';
import { SearchBar } from './SearchBar';
import { Toaster } from './Toaster';
/** @tsx h */

/**
 * Layout of the page, including nav and other tools
 * @param props
 * @returns
 */
export function Layout(props) {
  const user = useUser();

  return (
    <div class="page">
      <nav>
        <div class="container">
          <Link href="/">
            <h1>Gamer budget</h1>
          </Link>
          <SearchBar />
          <div class="actions">
            {!user ? (
              <Link href="/user/login">Login</Link>
            ) : (
              [
                <Link href="/review/create">New review</Link>,
                <a
                  href="/user/logout"
                  onClick={async (e) => {
                    e.preventDefault();

                    await fetch('/api/user/logout');

                    setUser(null);
                    route('/');
                  }}
                >
                  Logout
                </a>,
              ]
            )}
          </div>
        </div>
      </nav>
      <main class="container">{props.children}</main>
      <Toaster />
      <footer>Made by rrfvtgb - {new Date().getFullYear()}</footer>
    </div>
  );
}
