import { h } from 'preact';
import { useState } from 'preact/hooks';

interface ToastMessage {
  message: string;
  position: number;
}

let toasts: Record<string, ToastMessage> = {};
let setToasts = null;

let _id = 0;

export function toast(message: string) {
  const mid = _id++;
  const positions = {};

  for (const tid in toasts) {
    positions[toasts[tid].position] = true;
  }

  let position = 0;

  while (positions[position]) {
    position++;
  }

  const newToasts = {
    ...toasts,
    [mid]: { message: message, position: position },
  };

  setToasts(newToasts);

  console.log(message);

  setTimeout(() => {
    removeToast(mid.toString());
  }, 5000);
  return mid;
}

export function removeToast(mid: string) {
  const newToasts = { ...toasts };
  delete newToasts[mid];

  setToasts(newToasts);
}

export function Toaster() {
  [toasts, setToasts] = useState<Record<string, ToastMessage>>({});

  return (
    <div class="toaster">
      {Object.entries(toasts).map(([key, _toast]) => (
        <div
          key={key}
          class="_"
          style={{ bottom: `${_toast.position * 2.25 + 1}rem` }}
        >
          {_toast.message}
        </div>
      ))}
    </div>
  );
}
