// Must be the first import
if (process.env.NODE_ENV === 'development') {
  // Must use require here as import statements are only allowed
  // to exist at top-level.
  require('preact/debug');

  console.log(
    '%cDebug tools are ready to use',
    'color: #fff; background: #c58900; border-radius: 3px; padding: 2px 5px;',
  );
} else {
  console.log(
    '%cBudget gamer %c%s',
    'color: #fff; background: #333333; border-radius: 3px 0 0 3px; padding: 2px 5px;',
    'color: #fff; background: #c58900; border-radius: 0 3px 3px 0; padding: 2px 5px;',
    process.env.NODE_ENV,
  );
}

import Router from 'preact-router';
import { h, render } from 'preact';
import AsyncRoute from 'preact-async-route';

import { Layout } from './components/Layout';
import { NotFoundPage } from './pages/errors/404';

const Main = () => (
  <Layout>
    <Router>
      <AsyncRoute
        getComponent={async () => (await import('./pages/Home')).Home}
        path="/"
      />
      <AsyncRoute
        getComponent={async () => (await import('./pages/Search')).Search}
        path="/search/:query?"
      />
      <AsyncRoute
        getComponent={async () => (await import('./pages/user/Login')).Login}
        path="/user/login"
      />
      <AsyncRoute
        getComponent={async () =>
          (await import('./pages/review/Create')).Create
        }
        path="/review/create"
      />
      <NotFoundPage default />
    </Router>
  </Layout>
);

/*
    //<About path="/about" />
    // Advanced is an optional query
    //<Search path="/search/:query/:advanced?" />
	*/
for (const children of document.body.childNodes) {
  children.remove();
}

render(<Main />, document.body);
