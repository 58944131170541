import { useEffect, useState } from 'preact/hooks';

export function userData() {
  if (typeof sessionStorage === 'undefined') {
    return null;
  }

  const user = sessionStorage.getItem('user');

  if (!user) {
    return null;
  }

  return JSON.parse(user);
}

let _user = userData();
const userCallbacks = [];

export function setUser(user) {
  _user = user;

  for (const callback of userCallbacks) {
    callback(user);
  }

  if ('sessionStorage' in window) {
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('user');
    }
  }
}

export function can(...permissions: string[]): boolean {
  if (!permissions) {
    return true;
  }

  const user = userData();

  if (!user) {
    return false;
  }

  for (const permission of permissions) {
    if (!user.permissions.includes(permission)) {
      return false;
    }
  }

  return true;
}

export function useUser() {
  const [user, _setUser] = useState(_user);

  useEffect(() => {
    userCallbacks.push(_setUser);

    return () => {
      const index = userCallbacks.indexOf(_setUser);
      userCallbacks.splice(index, 1);
    };
  }, []);

  return user;
}

export function usePermissions(...permissions: string[]) {
  useUser();

  return can(...permissions);
}
