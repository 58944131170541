import { h } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import { isClient } from '../utils';

/** @jsx h */
/**
 * Get the search query for the client
 */
function getSearchQuery() {
  if (isClient()) {
    return location.pathname.match(/search\/([^\/]*)/)?.[1] ?? '';
  }
  return '';
}

/**
 * Search bar for the entire application
 * @returns
 */
export function SearchBar() {
  const [text, setText] = useState(decodeURI(getSearchQuery()));

  return (
    <div class="search-bar">
      <input
        name="search"
        type="search"
        class="inline large"
        value={text}
        onInput={(event) => setText((event.target as HTMLInputElement).value)}
        onKeyPress={(event) => {
          if (event.key == 'Enter') {
            route('/search/' + text);
          }
        }}
      />
    </div>
  );
}
